<template>
  <div>

    <div class="row mb-3">
      <div class="col-12">
        <div class="progress" style="height: 20px;">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :style="`width: ${progress}%;`" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100">Progress: {{ progress }}%</div>
      </div>
      </div>
    </div>

    <div class="d-flex align-items-start">
      <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">

          <div class="card">
            <div class="card-body">
              <div class="image-container" @contextmenu.prevent>
                <button class="btn btn-sm burger btn-primary" @click="toggleImage"><i v-show="faceShowing === 'background'" class="material-icons">face</i><i v-show="faceShowing === 'face'" class="material-icons">image</i></button>
                <img v-viewer v-show="faceShowing === 'face'" :src="faceSas" 
                alt="Face Image" class="face-image">
                <img v-viewer v-show="faceShowing === 'background'" :src="backgroundSas" alt="Face Image" class="face-image">
              </div>
            </div>
          </div>

          <button class="nav-link" :class="{'active': currentStep === 1}" id="v-pills-1-tab" data-bs-toggle="pill" data-bs-target="#v-pills-1" type="button" role="tab" aria-controls="v-pills-1" aria-selected="true">Step 1 - Basic Details</button>

          <button :disabled="!steps[2]" class="nav-link" :class="{'active': currentStep === 2}" id="v-pills-2-tab" data-bs-toggle="pill" data-bs-target="#v-pills-2" type="button" role="tab" aria-controls="v-pills-2" aria-selected="false">Step 2 - Incident Description</button>

          <button :disabled="!steps[3]" class="nav-link" :class="{'active': currentStep === 3}" id="v-pills-3-tab" data-bs-toggle="pill" data-bs-target="#v-pills-3" type="button" role="tab" aria-controls="v-pills-3" aria-selected="false">Step 3 - Witnesses</button>

          <button :disabled="!steps[4]" class="nav-link" :class="{'active': currentStep === 4}" id="v-pills-4-tab" data-bs-toggle="pill" data-bs-target="#v-pills-4" type="button" role="tab" aria-controls="v-pills-4" aria-selected="false">Step 4 - Evidence</button>

          <button :disabled="!steps[5]" class="nav-link" :class="{'active': currentStep === 5}" id="v-pills-5-tab" data-bs-toggle="pill" data-bs-target="#v-pills-5" type="button" role="tab" aria-controls="v-pills-5" aria-selected="false">Step 5 - Review</button>
      </div>
      <div class="tab-content" id="v-pills-tabContent">
          <div class="tab-pane fade" :class="{'show active': currentStep === 1}" id="v-pills-1" role="tabpanel" aria-labelledby="v-pills-home-tab">

            <div class="alert alert-custom alert-indicator-left indicator-warning" role="alert">
              <div class="alert-content">
                  <span class="alert-title">Warning!</span>
                  <span class="alert-text">Fields marked with a <span class="text-danger">*</span> are required and must be completed before you can continue!</span>
              </div>
            </div>
            
            <div class="row">
              <div class="col-6">

                <div class="mb-3 form-group">
                  <label for="incident_type" class="form-label">Incident Type <span class="text-danger">*</span></label>
                  <Multiselect
                      :class="{'multi-dark': $store.state.auth.status.dark}"
                      mode="tags"
                      placeholder="Select types"
                      :close-on-select="false"
                      :searchable="true"
                      :create-option="false"
                      :multiple="true"
                      :options="types"
                      label="name"
                      track-by="id"
                      name="types"
                      class="m-b-sm"
                      v-model="incident.types"
                  />
                  <p class="text-mute" v-show="incident.types.length > 0">
                    Please select what type of incident occured. You can select multiple types but they must all relate to the same person.
                  </p>
                </div>

              </div>
              <div class="col-6">

              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="mb-3 form-group">
                  <label for="incident_date" class="form-label">Incident Date <span class="text-danger">*</span></label>
                  <input type="date" class="form-control" id="incident_date" v-model="incident.date">
                  <p class="text-mute">
                    Please select the date the incident occured.
                  </p>
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3 form-group">
                  <label for="incident_time" class="form-label">Incident Time <span class="text-danger">*</span></label>
                  <input type="time" class="form-control" id="incident_time" v-model="incident.time">
                  <p class="text-mute">
                    Please select the time the incident occured. If you are reporting someone for multiple incidents that occurred at different times, please make seprarate reports.
                  </p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="mb-3 form-group">
                  <label for="police_reference" class="form-label">Police Reference</label>
                  <input type="text" class="form-control" id="police_reference" v-model="incident.police_reference">
                  <p class="text-mute">If this incident was reported to the police, please provide the crime reference number you were provided with. If you haven't reported this incident to the police yet, you can add the police reference after the incident has been created.</p>
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3 form-group">
                  <label for="police_force" class="form-label">Police Force</label>
                  <Multiselect
                    :class="{'multi-dark': $store.state.auth.status.dark}"
                    mode="tags"
                    placeholder="Select police force"
                    :close-on-select="false"
                    :searchable="true"
                    :create-option="false"
                    :multiple="false"
                    :options="policeForces"
                    name="police_force"
                    class="m-b-sm"
                    v-model="incident.police_force"
                  />
                  <p class="text-mute">If this incident was reported to the police, please provide the police force you reported the incident to. If you haven't reported this incident to the police yet, you can add the police force after the incident has been created.</p>
                </div>
              </div>
            </div>

            <button class="btn btn-sm btn-outline-primary float-end" :disabled="!stepOneReady" @click="completeStepOne">Continue</button>

          </div>
          <div class="tab-pane fade" :class="{'show active': currentStep === 2}" id="v-pills-2" role="tabpanel" aria-labelledby="v-pills-profile-tab">
            
            <div class="alert alert-custom alert-indicator-left indicator-warning" role="alert">
              <div class="alert-content">
                  <span class="alert-title">Warning!</span>
                  <span class="alert-text">Fields marked with a <span class="text-danger">*</span> are required and must be completed before you can continue!</span>
              </div>
            </div>

            <p><strong>What like makes a good incident description?</strong></p>
            <p></p>

            <div class="row">
              <div class="col-12">

                <div class="mb-3 form-group">
                  <label for="description" class="form-label">Description <span class="text-danger">*</span></label>
                  <textarea class="form-control" id="description" rows="15" v-model="incident.description"></textarea>
                  <p class="text-mute">Please provide a detailed description of the incident. This will help us understand what happened.<span class="float-end">Minimum 100 characters. <span :class="{'text-warning': incident.description?.length < 100, 'text-success': incident.description?.length > 99}">{{ incident.description?.length || 0 }}</span>/100</span></p>
                </div>

              </div>
            </div>

            <button class="btn btn-sm btn-outline-primary float-end" :disabled="!stepTwoReady" @click="completeStepTwo">Continue</button>

          </div>
          <div class="tab-pane fade" :class="{'show active': currentStep === 3}" id="v-pills-3" role="tabpanel" aria-labelledby="v-pills-messages-tab">

            <table class="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Notes</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="w in witnesses" :key="w.id + '-witness'">
                  <td>{{ w.witness }}</td>
                  <td>{{ w.type }}</td>
                  <td>{{ w.notes }}</td>
                  <td>
                    <button class="btn btn-sm btn-danger" @click="removeWitness(w)">Remove</button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input v-model="witness.witness" type="text" class="form-control" placeholder="Name">
                  </td>
                  <td>
                    <select v-model="witness.type" class="form-control">
                      <option>Staff</option>
                      <option>Public</option>
                      <option>Emergency Services</option>
                    </select>
                  </td>
                  <td>
                    <textarea v-model="witness.notes" class="form-control" rows="2" placeholder="Notes"></textarea>
                  </td>
                  <td>
                    <button class="btn btn-sm btn-primary" @click="addWitness">Add</button>
                  </td>
                </tr>
              </tbody>
            </table>

            <button class="btn btn-sm btn-outline-primary float-end" @click="completeStepThree">Continue</button>

          </div>  
          <div class="tab-pane fade" :class="{'show active': currentStep === 4}" id="v-pills-4" role="tabpanel" aria-labelledby="v-pills-x-tab">

            <div class="alert alert-custom alert-indicator-left indicator-warning" role="alert">
              <div class="alert-content">
                  <span class="alert-title">Warning!</span>
                  <span class="alert-text">Fields marked with a <span class="text-danger">*</span> are required and must be completed before you can continue!</span>
              </div>
            </div>

            <p><strong>What evidence can I provide?</strong></p>
            <ul>
              <li>CCTV Video Footage</li>
              <li>CCTV Still Images</li>
              <li>Signed witness statements</li>
              <li>Receipts</li>
              <li>Invoices</li>
              <li>Police reports</li>
            </ul>

            <p>In order to assist our verification team with approving your incident as quickly as possible, please try to provide as much evidence as you can to confirm the description of the incident you have provided. Short CCTV clips of the incident are very useful when assessing what has happened.</p>

            <p>For example, if someone has stolen from you we would ideally be looking for a short clip of them taking the items from the shelves. If a member or members of staff attempted to stop the individual, a clip or still images of this will also help.</p>
            
            <div class="row">
              <div class="col-12">

                <table class="table">
                  <thead>
                    <tr>
                      <th>File <span class="text-danger">*</span></th>
                      <th>Name</th>
                      <th>Description <span class="text-danger">*</span></th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="asset in incident.assets" :key="asset.id">
                      <td>
                        <input @change="(ev) => selectAsset(ev, asset)" accept="image/*,video/*,application/pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain" type="file" class="form-control">
                      </td>
                      <td>
                        <input v-model="asset.name" type="text" class="form-control">
                      </td>
                      <td>
                        <input v-model="asset.description" type="text" class="form-control">
                        <span class="text-mute">Please provide a description of the evidence you are providing. This will help our verification team understand what the evidence is showing.</span>
                      </td>
                      <td>
                        <button @click="removeAsset(asset)" class="btn btn-sm btn-outline-danger float-end">Remove</button>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="4">
                        <button class="btn btn-sm btn-outline-primary float-end" @click="addAsset">Add Asset</button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <p>We require a minimum of 1 piece of evidence. {{ selectedAssets }}/1</p>

                <button class="btn btn-sm btn-outline-primary float-end" :disabled="!stepThreeReady" @click="completeStepFour">Continue</button>

              </div>
            </div>

          </div>
          <div class="tab-pane fade" :class="{'show active': currentStep === 5}" id="v-pills-5" role="tabpanel" aria-labelledby="v-pills-settings-tab">
            
            <p>Please review the details that you have provided below, and, if you are happy they are correct click sign and submit at the end.</p>

            <div class="row">
              <div class="col-6">
                <p><strong style="text-decoration: underline;">Incident Details</strong></p>
                <p><strong>Incident Type:</strong> {{ incident.types.map(type => type.name).join(', ') }}</p>
                <p><strong>Incident Date:</strong> {{ incident.date }}</p>
                <p><strong>Incident Time:</strong> {{ incident.time }}</p>
                <p><strong>Police Reference:</strong> {{ incident.police_reference }}</p>
                <p><strong>Police Force:</strong> {{ incident.police_force }}</p>
              </div>
              <div class="col-6">
                <p>&nbsp;</p>
                <p><strong>Incident Description</strong></p>
                <p class="incident_description">{{ incident.description }}</p>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12">
                <p><strong style="text-decoration: underline;">Evidence</strong></p>
                <table class="table">
                  <thead>
                    <tr>
                      <th>File</th>
                      <th>Name</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="asset in incident.assets" :key="asset.id">
                      <td>
                        <img v-if="asset.image_url" :src="asset.image_url" alt="Asset" style="max-width: 400px;">
                        <video v-if="asset.video_url" :src="asset.video_url" alt="Asset" controls style="max-width: 400px;"></video>
                      </td>
                      <td>{{ asset.name }}</td>
                      <td>{{ asset.description }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12">
                <p><strong style="text-decoration: underline;">Declaration of Truth</strong></p>
                <p>By submitting this form, you certify that all information provided herein is accurate and complete to the best of your knowledge. You understand that knowingly submitting false or misleading information can result in prosecution and legal liability. Please verify all details before submitting to ensure their correctness.</p>

                <div class="form-group">
                  <label for="name">Name <span class="text-danger">*</span></label>
                  <input type="text" class="form-control" id="name" v-model="name">
                  <p class="text-mute">Please enter your full name to confirm the details you have provided are correct and accurate to the best of your knowledge.</p>
                </div>

                <div class="form-group">
                  <label for="password">Password <span class="text-danger">*</span></label>
                  <input type="password" class="form-control" id="password" v-model="password">
                  <p class="text-mute">Please enter your password to confirm the details you have provided are correct and accurate to the best of your knowledge.</p>
                </div>


                <button :disabled="!stepFourReady" class="btn btn-sm btn-outline-primary float-end" @click="createIncident">Sign and Submit</button>
              </div>
            </div>

          </div>
      </div>
    </div>
  </div>

  <!-- Upload Modal -->
  <div class="modal fade" id="uploadModal" tabindex="-1" aria-labelledby="uploadModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="uploadModalLabel">Creating Incident</h5>
                <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              
              <div class="row mb-4">
                <div class="col-12 text-center">
                  <div class="spinner-border" style="width: 8rem; height: 8rem;" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-12">
                  <p class="text-center">Please wait while we create your incident. This may take a few moments.</p>
                  <p class="text-danger text-center">Do not close this page whilst the incident is being created!</p>

                  <p class="text-center mt-2 text-white">{{ upload_status }}</p>
                </div>
              </div>

            </div>
        </div>
    </div>
  </div>

</template>

<script>
import { Multiselect } from 'vue-multiselect';
import authHeader from '../../services/auth-header';
import axios from 'axios';
import { v4 } from 'uuid';
import LargeFileUpload from '../../services/largeFileUpload';

export default {
  name: 'IncidentForm',
  components: {
    Multiselect
  },
  props: {
    faceId: {
      type: String,
      required: true
    },
    faceSas: {
      type: String,
      required: true
    },
    backgroundSas: {
      type: String,
      required: true
    },
    created_at: {
      type: String,
      required: true
    },
    site_id: {
      type: String,
      required: true
    },
  },
  data(){
    return {
      types: [],
      incident: {
        types: [],
        date: null,
        time: null,
        police_reference: null,
        police_force: null,
        description: null,
        assets: [
          {
            id: v4(),
            file: null,
            name: null,
            description: null
          }
        ]
      },
      password: null,
      name: null,
      witnesses: [],
      witness: {
        witness: null,
        type: 'Staff',
        notes: null
      },
      face: {
        id: null,
        site_id: null,
        camera_id: null,
        images: [],
        age: {
          min: null,
          max: null
        },
        site_collection_id: null,
        quality: null,
        confidence: null,
        aws_face_id: null,
        created_at: null,
      },
      steps: {
        1: true,
        2: false,
        3: false,
        4: false,
        5: false
      },
      currentStep: 1,
      policeForces: ["Avon and Somerset Constabulary", "Bedfordshire Police", "Cambridgeshire Constabulary", "Cheshire Constabulary", "City of London Police", "Cleveland Police", "Cumbria Constabulary", "Derbyshire Constabulary", "Devon & Cornwall Police", "Dorset Police", "Durham Constabulary", "Essex Police", "Gloucestershire Constabulary", "Greater Manchester Police", "Hampshire Constabulary", "Hertfordshire Constabulary", "Humberside Police", "Kent Police", "Lancashire Constabulary", "Leicestershire Police", "Lincolnshire Police", "Merseyside Police", "Metropolitan Police Service", "Norfolk Constabulary", "North Yorkshire Police", "Northamptonshire Police", "Northumbria Police", "Nottinghamshire Police", "South Yorkshire Police", "Staffordshire Police", "Suffolk Constabulary", "Surrey Police", "Sussex Police", "Thames Valley Police", "Warwickshire Police", "West Mercia Police", "West Midlands Police", "West Yorkshire Police", "Wiltshire Police"],
      uploadModal: null,
      submitting: false,
      upload_status: "Creating your incident",
      faceShowing: 'face'
    }
  },
  mounted(){

    if(!this.faceId || !this.site_id){
      this.$router.push(`/face`);
      return;
    }

    this.getTypes();
    this.uploadModal = new bootstrap.Modal(document.getElementById('uploadModal'), {
      keyboard: false
    });
    this.getFace();
  },
  methods: {
    toggleImage(){
      if(this.faceShowing === 'face'){
        this.faceShowing = 'background';
      } else {
        this.faceShowing = 'face';
      }
    },
    getFace(){
      axios.get(`https://api.varsanpr.com/customer/face/faces/${this.faceId}?site_id=${this.site_id}`, {
        headers: authHeader()
      })
      .then(response => {
        this.face = response.data;
      })
      .catch(e => {
        console.log(e);
      });
    },
    getTypes(){
      axios.get(`https://api.varsanpr.com/customer/incident/incidents/types`, {
        headers: authHeader()
      })
      .then(response => {
        this.types = response.data.types;
      })
      .catch(e => {
        console.log(e);
      });
    },
    completeStepOne(){
      this.steps[2] = true;
      this.currentStep = 2;
    },
    completeStepTwo(){
      this.steps[3] = true;
      this.currentStep = 3;
    },
    completeStepThree(){
      this.steps[4] = true;
      this.currentStep = 4;
    },
    completeStepFour(){
      this.steps[5] = true;
      this.currentStep = 5;
    },
    addAsset(){
      this.incident.assets.push({
        id: v4(),
        file: null,
        name: null,
        description: null
      });
    },
    removeAsset(asset){
      this.incident.assets = this.incident.assets.filter(a => a.id !== asset.id);
    },
    selectAsset(ev, asset){
      asset.file = ev.target.files[0];
      if(asset.file.type.includes('image')){
        asset.image_url = URL.createObjectURL(asset.file);
      }
      if(asset.file.type.includes('video')){
        asset.video_url = URL.createObjectURL(asset.file);
      }
    },
    createIncident(){

      if(this.submitting) return;
      this.submitting = true;

      this.uploadModal.show()

      axios.post(`https://api.varsanpr.com/customer/incident/incidents`, {
        site_id: this.site_id,
        faces: [this.faceId],
        types: this.incident.types.map(type => type.name),
        description: this.incident.description,
        incident_date: this.incident.date,
        incident_time: this.incident.time + ":00",
        police_ref: this.incident.police_reference,
        police_force: this.incident.police_force,
        name: this.name,
        password: this.password,
        witnesses: this.witnesses
      }, {
        headers: authHeader()
      })
      .then(response => {

        this.upload_status = 'Incident created successfully. Uploading assets...';
        let incident_id = response.data.incident_id;

        this.uploadAssets(incident_id);

      })
      .catch(error => {
        this.$error(error.response.data.message || 'An error occurred whilst creating the incident.', error);
        this.uploadModal.hide();
      })
      .finally(() => {
        this.submitting = false;
      });

    },
    async uploadAssets(incident_id){

      // Step 1: POST to create asset
      // Step 2: Use SAS URL to upload file
      // Step 3: Mark incident as complete

      for await (let asset of this.incident.assets){

        let asset_id = null;
        let sas_url = null;

        await axios.post(`https://api.varsanpr.com/customer/incident/incidents/asset`, {
          incident_id: incident_id,
          name: asset.name,
          description: asset.description,
          site_id: this.site_id
        }, {
          headers: authHeader()
        })
        .then(response => {
          asset_id = response.data.asset.id;
          sas_url = response.data.sas;
        })
        .catch(error => {
          this.$error(error.response.data.message || 'An error occurred whilst creating the asset.', error);
        });

        if(asset_id){
          this.upload_status = `Uploading ${asset.name || asset.file?.name}...`;
          let upload = new LargeFileUpload(asset.file, sas_url);
          upload.on('progress', (progress) => {
            this.upload_status = `Uploading ${asset.name || asset.file?.name}... ${progress}%`;
          });
          await upload.upload();
        }

      }

      await axios.post(`https://api.varsanpr.com/customer/incident/incidents/complete`, {
        incident_id: incident_id
      }, {
        headers: authHeader()
      })
      .then(response => {
        this.upload_status = 'Incident created successfully. Assets uploaded successfully.';
        this.$success('Incident created successfully. Assets uploaded successfully.');

        this.$router.push(`/incidents/${incident_id}?site_id=${this.site_id}`);
      })
      .catch(error => {
        this.$error(error.response.data.message || 'An error occurred whilst completing the incident.', error);
      })
      .finally(() => {
        this.uploadModal.hide();
      });

    },
    addWitness(){
      this.witnesses.push({
        id: v4(),
        witness: this.witness.witness,
        type: this.witness.type,
        notes: this.witness.notes
      });
    },
    removeWitness(witness){
      this.witnesses = this.witnesses.filter(w => w.id !== witness.id);
    }
  },
  computed: {
    stepOneReady: function(){
      return this.incident.types.length > 0 && this.incident.date && this.incident.time && true;
    },
    stepTwoReady: function(){
      return this.incident.description?.length > 99;
    },
    stepThreeReady: function(){
      if(this.incident.assets.filter((asset) => {
        return !asset.description || !asset.file;
      }).length > 0) return false;
      return this.selectedAssets > 0;
    },
    stepFourReady: function(){
      let nameLength = this.name?.length > 0;
      let passwordLength = this.password?.length > 0;
      return nameLength && passwordLength;
    },
    selectedAssets: function(){
      return this.incident.assets.filter(a => a.file).length;
    },
    progress: function(){
      let totalPoints = 5;
      let points = 0;

      if(this.incident.types.length > 0) points++;
      if(this.incident.date) points++;
      if(this.incident.time) points++;
      if(this.incident.description?.length > 249) points++;
      if(this.incident.assets.filter((asset) => {
        return !asset.description || !asset.file;
      }).length === 0 && this.selectedAssets > 0) points++;

      return (points / totalPoints) * 100;
    }
  }
}
</script>

<style scoped>
.nav-link {
  text-align: left;
  color: white;
}
.nav-link.active {
  color: white;
}
.nav-link:disabled {
  opacity: 0.5;
}
.tab-content {
  width: 80%;
}
.text-mute {
  opacity: 0.7;
}
.incident_description {
  white-space: pre-wrap;
  word-wrap: break-word;
}
.image-container {
  position: relative;
}

.image-container button {
  position: absolute;
  top: 5px;
  left: 5px;
}

.burger {
  width: 35px;
  height: 35px;
  line-height: 35px !important;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding: 0;
  border-radius: 0;
  vertical-align: middle !important;
  border-radius: 5px;
}

.burger i {
  vertical-align: middle !important;
  line-height: 35px !important;
  margin: 0 !important;
  font-size: 24px !important;
}

.face-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  margin: auto;
}
</style>
